import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'

export const AppDrawerContext = React.createContext()

const useStyles = makeStyles({
  list: {
    width: 240
  }
})

function AppDrawer ({ children }) {
  const [ isOpen, setIsOpen ] = useState(false)

  function open () { setIsOpen(true) }

  function close () { setIsOpen(false) }

  const context = {
    isOpen,
    open,
    close
  }

  const classes = useStyles()

  return (
    <AppDrawerContext.Provider value={context}>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={close}
        onOpen={open}
      >
        <List className={classes.list} onClick={close}>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText primary="Todos" />
          </ListItem>

          <ListItem button component={Link} to="/history">
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="History" />
          </ListItem>
        </List>
      </SwipeableDrawer>

      {children}
    </AppDrawerContext.Provider>
  )
}

export default AppDrawer
