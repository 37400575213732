import React, { useContext, useState } from 'react'
import { IconButton, InputBase, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Box, Zoom } from '@material-ui/core'
import { AddCircleOutline, Create } from '@material-ui/icons'

import { TodoContext } from '../Todoodloo'

function AddTodo () {
  const { addTodo } = useContext(TodoContext)

  const [ value, setValue ] = useState('')

  function handleChange (e) {
    setValue(e.target.value)
  }

  function add () {
    if (value) {
      addTodo({ text: value })

      setValue('')
    }
  }

  function handleSubmit (e) {
    e.preventDefault()

    add()
  }

  return (
    <Box zIndex={1} bgcolor="background.default" component={ListItem}>
      <ListItemIcon>
        <Box ml="9px" component={Create} />
      </ListItemIcon>
      <ListItemText>
        <form onSubmit={handleSubmit}>
          <InputBase
            fullWidth
            placeholder="What's to do?"
            value={value}
            variant="outlined"
            onChange={handleChange}
          />
        </form>
      </ListItemText>
      <Box zIndex={1} clone>
        <ListItemSecondaryAction>
            <Zoom in={Boolean(value)}>
              <IconButton onClick={add}>
                <AddCircleOutline />
              </IconButton>
            </Zoom>
        </ListItemSecondaryAction>
      </Box>
    </Box>
  )
}

export default React.memo(AddTodo)
