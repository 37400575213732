import React from 'react'
import moment from 'moment'

import isToday from './utils/is-today'
import usePersistentState from './hooks/usePersistentState'
import Main from './components/Main'
import objectFilter from './utils/object-filter'
import objectMap from './utils/object-map'

export const TodoContext = React.createContext(null)

const hydrateTodos = objectMap(
  todo => {
    const olderThanADayAndIncomplete = !todo.completed && !isToday(new Date(todo.timestamp))

    if (olderThanADayAndIncomplete) {
      return { ...todo, timestamp: moment().startOf('day').valueOf() }
    }

    return todo
  }
)

function App () {
  const [ todos, setTodos ] = usePersistentState(
    'todos',
    [],
    hydrateTodos
  )

  function addTodo (todo) {
    const id = Date.now()

    setTodos(
      currentTodos => ({
        [ id ]: {
          id,
          timestamp: Date.now(),
          completed: false,
          ...todo
        },
        ...currentTodos
      })
    )
  }

  function deleteTodo (id) {
    setTodos(
      ({ [ id ]: deletedTodo, ...remainingTodos }) => remainingTodos
    )
  }

  function toggleTodo (id) {
    const { [ id ]: todo } = todos

    setTodos({
      ...todos,
      [ id ]: {
        ...todo,
        completed: !todo.completed
      }
    })
  }

  function reuseTodo (id) {
    const { [ id ]: todo } = todos

    setTodos({
      ...todos,
      [ id ]: {
        ...todo,
        completed: false,
        timestamp: Date.now()
      }
    })
  }

  const todaysTodos = objectFilter(
    todo => isToday(new Date(todo.timestamp)) || !todo.completed
  )(
    todos
  )

  const historicTodos = objectFilter(
    todo => !isToday(new Date(todo.timestamp))
  )(
    todos
  )

  const context = {
    todos,
    todaysTodos,
    historicTodos,
    addTodo,
    deleteTodo,
    toggleTodo,
    reuseTodo
  }

  return (
    <TodoContext.Provider value={context}>
      <Main />
    </TodoContext.Provider>
  )
}

export default App
