import React from 'react'
import MuiAppBar from '@material-ui/core/AppBar'
import { useScrollTrigger, makeStyles } from '@material-ui/core'

import TodoodlooToolBar from './TodoodlooToolBar'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  }
}))

export default function AppBar () {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })

  const classes = useStyles()

  return (
    <MuiAppBar
      className={classes.root}
      position="sticky"
      elevation={trigger ? 4 : 0}
    >
      <TodoodlooToolBar />
    </MuiAppBar>
  )
}
