import React, { useMemo } from 'react'
import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core'

import usePersistentState from '../hooks/usePersistentState'

export const ThemeContext = React.createContext()

export default function Theme ({ children }) {
  const [ themeType, setThemeType ] = usePersistentState('themeType', 'light')

  function toggleThemeType () {
    setThemeType(themeType === 'light' ? 'dark' : 'light')
  }

  const context = {
    themeType,
    toggleThemeType
  }

  const theme = useMemo(
    function () {
      return createMuiTheme({
        palette: {
          type: themeType,
          primary: {
            light: '#ffff6b',
            main: '#fdd835',
            dark: '#c6a700'
          },
          secondary: {
            light: '#6d6d6d',
            main: '#424242',
            dark: '#1b1b1b'
          },
          success: {
            light: '#66ffa6',
            main: '#00e676',
            dark: '#00b248'
          }
        }
      })
    },
    [ themeType ]
  )

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ThemeContext.Provider value={context}>
        {children}
      </ThemeContext.Provider>
    </MuiThemeProvider>
    
  )
}
