import React, { useContext, useState } from 'react'
import { motion } from 'framer-motion'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import CloseRounded from '@material-ui/icons/CloseRounded'
import { IconButton, ListItemText, ListItemIcon, makeStyles, Zoom, Box } from '@material-ui/core'

import { TodoContext } from '../Todoodloo'
import { Done } from '@material-ui/icons'

const transitionDuration = 300

function TodoItem ({ id }) {
  const {
    todos: { [ id ]: todo },
    deleteTodo,
    toggleTodo
  } = useContext(TodoContext)

  const [ isToBeDeleted, setIsToBeDeleted ] = useState(false)

  function scheduleDeletion () {
    setIsToBeDeleted(true)

    setTimeout(
      () => deleteTodo(id),
      transitionDuration
    )
  }

  function handleDeleteClick (event) {
    event.stopPropagation()

    scheduleDeletion()
  }

  function handleClick () {
    toggleTodo(id)
  }

  const containerProps = {
    /*
     * height of the list-item assuming a single row of text
     */
    style: { y: -58, overflow: 'hidden' },
    transition: { type: 'tween', duration: transitionDuration / 1000 },
    layoutTransition: (
      isToBeDeleted
        ? false
        : {
          type: 'tween',
          duration: transitionDuration / 1000,
          ease: 'easeInOut'
        }
    ),
    animate: {
      y: 0,
      x: isToBeDeleted ? '-100%' : 0
    }
  }

  return (
    <ListItem
      button
      onClick={handleClick}
      ContainerComponent={motion.li}
      ContainerProps={containerProps}
    >
      <ListItemIcon>
        <Zoom in={todo.completed}>
          <Box color="#00e676" component={Done} />
        </Zoom>
      </ListItemIcon>

      <ListItemText>
        {todo.text}
      </ListItemText>

      <ListItemSecondaryAction>
        <IconButton onClick={handleDeleteClick}>
          <CloseRounded />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default React.memo(TodoItem)
