export default function objectFilter (filter) {
  return function (object) {
    return Object.entries(object).reduce(
      (reduction, [ key, item ], index) => {
        if (filter(item, key, index)) {
          return { ...reduction, [ key ]: item }
        }
    
        return reduction
      },
      {}
    )
  }
}
