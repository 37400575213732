import React, { useContext } from 'react'
import { Toolbar, Grid, IconButton, Typography } from '@material-ui/core'
import { Menu } from '@material-ui/icons'

import ToggleThemeTypeIconButton from './ToggleThemeTypeIconButton'
import { AppDrawerContext } from './AppDrawer'

export default function TodoodlooToolBar () {
  const appDrawer = useContext(AppDrawerContext)

  return (
    <Toolbar>
      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <IconButton edge="start" onClick={appDrawer.open}>
            <Menu />
          </IconButton>
        </Grid>

        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="h6" color="textPrimary">
            Todoodloo
          </Typography>
        </Grid>

        <Grid item>
          <ToggleThemeTypeIconButton />
        </Grid>
      </Grid>
    </Toolbar>
  )
}
