import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Box } from '@material-ui/core'

import AppBar from './AppBar'
import AppDrawer from './AppDrawer'
import TodoList from './TodoList'
import TodoHistory from './TodoHistory'

export default function Main () {
  return (
    <AppDrawer>
      <Box height="100vh" display="flex" flexDirection="column">
        <AppBar />

        <Switch>
          <Route exact path="/" component={TodoList} />
          <Route path="/history" component={TodoHistory} />
        </Switch>
      </Box>
    </AppDrawer>
  )
}
