import React, { useContext, useMemo } from 'react'
import List from '@material-ui/core/List'

import { TodoContext } from '../Todoodloo'
import TodoItem from './TodoItem'
import AddTodo from './AddTodo'

function TodoList () {
  const { todaysTodos } = useContext(TodoContext)

  const displayedTodos = useMemo(
    function () {
      const todosByTimestamp = Object.values(todaysTodos).sort(
        function (todoA, todoB) {
          return todoB.timestamp - todoA.timestamp
        }
      )
    
      const pendingTodos = todosByTimestamp.filter(todo => !todo.completed)
    
      const completedTodos = todosByTimestamp.filter(todo => todo.completed)
    
      return [ ...pendingTodos, ...completedTodos ]
    },
    [ todaysTodos ]
  )

  function renderTodo (todo, index) {
    const last = index === displayedTodos.length - 1

    return (
      <TodoItem
        key={todo.id}
        id={todo.id}
        isLastChild={last}
      />
    )
  }

  return (
    <List disablePadding>
      <AddTodo />

      {displayedTodos.map(renderTodo)}
    </List>
  )
}

export default React.memo(TodoList)
