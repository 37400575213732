export default function groupBy (items, groupingKeyGenerator) {
  return items.reduce(
    function (groupedItems, item, index) {
      const groupingKey = groupingKeyGenerator(item, index)

      if (groupingKey in groupedItems) {
        return {
          ...groupedItems,
          [ groupingKey ]: [ ...groupedItems[groupingKey], item ]
        }
      }

      return {
        ...groupedItems,
        [ groupingKey ]: [ item ]
      }
    },
    {}
  )
}
