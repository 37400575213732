import React, { useContext } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { IconButton, ListItemText, ListItemIcon, Box } from '@material-ui/core'

import { TodoContext } from '../Todoodloo'
import { Done, History } from '@material-ui/icons'

function HistoricTodoItem ({ id }) {
  const { todos: { [ id ]: todo }, reuseTodo } = useContext(TodoContext)

  function handleReuseClick () {
    reuseTodo(id)
  }

  return (
    <ListItem key={todo.id}>
      <ListItemIcon>
        <Box color="#00e676" component={Done} />
      </ListItemIcon>

      <ListItemText>
        {todo.text}
      </ListItemText>

      <ListItemSecondaryAction>
        <IconButton onClick={handleReuseClick}>
          <History />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default React.memo(HistoricTodoItem)
