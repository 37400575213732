import { useEffect, useState, useRef } from 'react'

export default function usePersistentState (
  /*
   * key by which to index persisted item in localStorage
   */
  key,
  /*
   * initial initial state, meaning that this is only
   * used the first time ever this hook runs in a new
   * end-user client's environment (since the 2nd time
   * there'll already be a persisted item which is going
   * to be used as initial state instead)
   */
  initialState,
  /*
   * optional hydrator, function which accepts stored
   * item and returns a compatible state, however formatted
   * to the liking of the user of this hook
   */
  hydrator = value => value
) {
  const isHydrated = useRef(false)

  const [ state, setState ] = useState(initialState)

  /*
   * initial state hydration, this is supposed to run only
   * one time at 'mount' (not hooks lingo eh?)
   * the key is only in the dependency array so that react
   * won't whine about it, a change in the passed key could
   * be considered a mis-use of this hook
   */
  useEffect(
    function () {
      const storedItem = window.localStorage.getItem(key)

      if (!storedItem) return

      const hydration = JSON.parse(storedItem)

      if (hydrator) {
        setState(hydrator(hydration))
      } else {
        setState(hydration)
      }

      isHydrated.current = true
    },
    [ key ]
  )

  useEffect(
    function () {
      if (isHydrated.current) {
        window.localStorage.setItem(
          key,
          JSON.stringify(state, null, 2)
        )
      }
    },
    [ state, key ]
  )

  return [ state, setState ]
}
