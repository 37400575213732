import React, { useContext } from 'react'
import moment from 'moment'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'

import groupBy from '../utils/group-by'
import { TodoContext } from '../Todoodloo'
import HistoricTodoItem from './HistoricTodoItem'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflow: 'auto'
  },

  listSection: {
    backgroundColor: 'inherit',
  },

  listSubheader: {
    backgroundColor: theme.palette.background.default,
  },

  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}))

export default function TodoHistory () {
  const classes = useStyles()

  const { historicTodos } = useContext(TodoContext)

  const groupedByDay = groupBy(
    Object.values(historicTodos),
    todo => moment(todo.timestamp).format('dddd, MMMM Do YYYY')
  )

  return (
    <List disablePadding className={classes.root}>
      {Object.entries(groupedByDay).map(
        ([ date, todos ]) => (
          <li key={date} className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader className={classes.listSubheader}>
                {date}
              </ListSubheader>
              {todos.map(todo => <HistoricTodoItem id={todo.id} />)}
            </ul>
          </li>
        )
      )}
    </List>
  )
}
