import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import './index.css'

import Todoodloo from './Todoodloo'
import * as serviceWorker from './serviceWorker'
import Theme from './components/Theme'

ReactDOM.render(
  <Router>
    <Theme>
      <Todoodloo />
    </Theme>
  </Router>,
  document.getElementById('root')
)

serviceWorker.register()
