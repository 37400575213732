/*
 * function => (function, function) => object
 *
 * replaces every property on the object with the return value of
 * the value mapper function given the current value and the current key
 *
 * optionally replaces every key on the object with the return value of
 * the key mapper function given the value computed from the value mapper
 * and the current key
 */
export default function objectMap (mapValue, mapKey) {
  return function (object = {}) {
    function reducer (mappedObject, entry) {
      const [ key, value ] = entry

      const mappedValue = mapValue(value, key)

      /*
       * use the mappedValue as input to the mapKey function
       * instead of using the initial value of that property
       * for the use-case of the key needing to be computed
       * from something contained in the return from mapValue
       */
      const mappedKey = mapKey ? mapKey(mappedValue, key) : key

      return { ...mappedObject, [ mappedKey ]: mappedValue }
    }

    return Object.entries(object).reduce(reducer, {})
  }
}
