import React, { useContext } from 'react'
import { IconButton } from '@material-ui/core'
import { Brightness5Outlined, Brightness2Outlined } from '@material-ui/icons'

import { ThemeContext } from './Theme'
  
export default function ToggleThemeTypeIconButton () {
  const { themeType, toggleThemeType } = useContext(ThemeContext)

  return (
    <IconButton onClick={toggleThemeType}>
      {
        themeType === 'light'
          ? <Brightness5Outlined />
          : <Brightness2Outlined />
      }
    </IconButton>
  )
}
